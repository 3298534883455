<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <!-- <vuexy-logo /> -->
      <h2 class="brand-text text-primary ml-1">
        PHARMACONNECT
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Vous n'êtes actuellement associé à aucun grossiste.
        </h2>
        <p class="mb-2">
          Aucun grossiste associé à votre compte. Veuillez contacter l'administrateur pour obtenir l'accès à un grossiste
          et commencer à utiliser l'application.
        </p>

        <b-button variant="primary" class="mb-2 btn-sm-block" :to="loginRoute()">Back to Home
        </b-button>
        <b-button variant="secondary" class="mb-2 ml-2 btn-sm-block" @click="logout()">Déconnexion
        </b-button>

        <!-- image -->
        <b-img fluid :src="imgUrl" alt="Error page" />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { initialAbility } from '@/libs/acl/config'

export default {
  components: {
    // VuexyLogo,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    loginRoute() {
      const user = JSON.parse(localStorage.getItem('userData'))
      return getHomeRouteForLoggedInUser(user ? user.role : null)
    },
    logout() {
      this.$store.dispatch('app-userdata/logout').catch(error => console.log(error)).finally(() => {
      });
      this.$ability.update(initialAbility)
      this.$router.push({ name: 'auth-login' })
      this.$forceUpdate()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
